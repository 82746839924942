import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import MedicalSoftwareWrapper from "@components/layouts/medicalsoftwareWrapper";

import TopSection from "@components/medicalsoftwarequotes/topSection";
import styles from "./style.module.scss";
import OffersList from "@components/shared/offersList";

const DoYouKnow = dynamic(
    () => import("@components/medicalsoftwarequotes/doYouKnow"),
);
const Benefits = dynamic(
    () => import("@components/medicalsoftwarequotes/benefits"),
);
export default function MedicalSoftwareHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <MedicalSoftwareWrapper>
            <TopSection />
            <div className={`px-4vw lg:px-4 ${styles["mxWrapper"]}`}>
                <section>
                    <OffersList offers={domain?.defaultOffers} />
                </section>
                <Benefits />
                <DoYouKnow />
            </div>
        </MedicalSoftwareWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
